import React from "react"
import "./BottomCtaSection.scss"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import useCheckIsMobile from "hooks/useCheckIsMobile"
import { Link } from "gatsby"

interface BottomCtaSectionProps {
  title?: string
  desc?: any
  backgroundimage?: any
  integration?: any
  mobileText?: any
}

const BottomCtaSection: React.FC<BottomCtaSectionProps> = ({
  title,
  desc,
  backgroundimage,
  integration,
  mobileText,
}) => {
  const isMobile = useCheckIsMobile({ width: 700 })
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundimage?.url})`,
      }}
      className="BottomCtaSection"
    >
      {title && <div className="title">{title}</div>}
      {desc && (
        <div className="info-container">
          <PrismicRichText
            field={isMobile ? mobileText?.richText : desc?.richText}
            components={{
              paragraph: ({ children }) => <p className="info">{children}</p>,
            }}
          />
        </div>
      )}
      <div className="integrationItems">
        {integration?.map((item: any, index: number) => (
          <Link
            target="_blank"
            key={index}
            to={item?.url?.url}
            rel="noopener noreferrer"
          >
            <div className="integrationInfo">
              {item?.integration_images?.gatsbyImageData && (
                <GatsbyImage
                  image={item?.integration_images?.gatsbyImageData}
                  alt={item?.integration_images.alt ?? "Review item icons"}
                  objectFit="contain"
                />
              )}
              <div className="name">
                <div className="mobile-text">{item?.mobilename}</div>
                <div className="desktop-text">{item?.name}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BottomCtaSection
